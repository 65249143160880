import { IBasketItem } from '@/shared/api/getBasket/types';
import Image from 'next/image';
import { FC, useState } from 'react';
import {
  cvaGroup,
  cvaGroupDescr,
  cvaGroupTitle,
  cvaGroupTitleGreenText,
  cvaGroupTitleIcon,
  cvaItem,
  cvaItemImage,
  cvaItemImageWrap,
  cvaItems,
  cvaRoot,
  showMoreItems,
  showMoreItemsIcon,
  showMoreItemsText,
} from './StoreMapDetailsBodyStyles';
import IconCaretDown from '/public/icons/arrow-down.svg';
import IconAttention from '/public/icons/attention.svg';
import IconError from '/public/icons/error.svg';
import { BasketAvailableProduct } from '@/shared/api/postBasketAvailable/types';

interface Props {
  pickupItems: IBasketItem[];
  otherDeliveryItems: IBasketItem[];
  notAvailableItems: BasketAvailableProduct[];
}

const StoreMapDetailsBody: FC<Props> = ({
  pickupItems,
  otherDeliveryItems,
  notAvailableItems,
}) => {
  const [
    maxShowPickupItems,
    setMaxShowPickupItems,
  ] = useState<number>(4);
  const [
    maxShowOtherDeliveryItems,
    setMaxShowOtherDeliveryItems,
  ] = useState<number>(4);
  const [
    maxShowNotAvailableItems,
    setMaxShowNotAvailableItems,
  ] = useState<number>(4);

  const onClickShowMorePickupItems = () => {
    maxShowPickupItems < pickupItems.length
      ? setMaxShowPickupItems(pickupItems.length)
      : setMaxShowPickupItems(4);
  };

  const onClickShowMoreOtherDeliveryItems =
    () => {
      maxShowOtherDeliveryItems <
      otherDeliveryItems.length
        ? setMaxShowOtherDeliveryItems(
            otherDeliveryItems.length
          )
        : setMaxShowOtherDeliveryItems(4);
    };

  const onClickShowMoreNotAvailableItems = () => {
    maxShowNotAvailableItems <
    notAvailableItems.length
      ? setMaxShowNotAvailableItems(
          notAvailableItems.length
        )
      : setMaxShowNotAvailableItems(4);
  };

  return pickupItems?.length ? (
    <div className={cvaRoot()}>
      <div className={cvaGroup()}>
        <div className={cvaGroupTitle()}>
          Можно забрать{' '}
          <span
            className={cvaGroupTitleGreenText()}
          >
            сегодня
          </span>
        </div>
        <div className={cvaItems()}>
          {[...pickupItems]
            .splice(0, maxShowPickupItems)
            .map((item, index) => (
              <div
                className={cvaItem()}
                key={index}
              >
                <div
                  className={cvaItemImageWrap()}
                >
                  {item.product.images &&
                  item.product.images.length ? (
                    <Image
                      src={item.product.images[0]}
                      className={cvaItemImage()}
                      fill
                      alt=""
                    />
                  ) : null}
                </div>
              </div>
            ))}
        </div>
        {pickupItems?.length > 4 ? (
          <div
            className={showMoreItems()}
            onClick={() =>
              onClickShowMorePickupItems()
            }
          >
            <span className={showMoreItemsText()}>
              {pickupItems.length} товаров
            </span>{' '}
            <IconCaretDown
              className={showMoreItemsIcon({
                active:
                  maxShowPickupItems ===
                  pickupItems.length,
              })}
            />
          </div>
        ) : null}
      </div>
      {otherDeliveryItems?.length ? (
        <div className={cvaGroup()}>
          <div
            className={cvaGroupTitle({
              withIcon: true,
            })}
          >
            <IconAttention
              className={cvaGroupTitleIcon()}
            />
            Доставим другим способом
          </div>
          <div className={cvaGroupDescr()}>
            Данные товары могут быть доставлены
            другими способами: доставкой в ПВЗ,
            почтой или курьером. Выбор способа
            доставки доступен на следующем этапе
            оформления заказа
          </div>
          <div className={cvaItems()}>
            {[...otherDeliveryItems]
              .splice(
                0,
                maxShowOtherDeliveryItems
              )
              .map((item, index) => (
                <div
                  className={cvaItem()}
                  key={index}
                >
                  <div
                    className={cvaItemImageWrap()}
                  >
                    {item.product.images &&
                    item.product.images.length ? (
                      <Image
                        src={
                          item.product.images[0]
                        }
                        className={cvaItemImage()}
                        fill
                        alt=""
                      />
                    ) : null}
                  </div>
                </div>
              ))}
          </div>
          {otherDeliveryItems?.length > 4 ? (
            <div
              className={showMoreItems()}
              onClick={() =>
                onClickShowMoreOtherDeliveryItems()
              }
            >
              <span
                className={showMoreItemsText()}
              >
                {otherDeliveryItems.length}{' '}
                товаров
              </span>{' '}
              <IconCaretDown
                className={showMoreItemsIcon({
                  active:
                    maxShowOtherDeliveryItems ===
                    otherDeliveryItems.length,
                })}
              />
            </div>
          ) : null}
        </div>
      ) : null}
      {notAvailableItems?.length ? (
        <div className={cvaGroup()}>
          <div
            className={cvaGroupTitle({
              withIcon: true,
            })}
          >
            <IconError
              className={cvaGroupTitleIcon()}
            />
            Не сможем доставить
          </div>
          <div
            className={cvaGroupDescr({
              color: 'red',
            })}
          >
            Мы не сможем доставить часть товаров в
            ваш город. Они будут удалены из заказа
          </div>
          <div className={cvaItems()}>
            {[...notAvailableItems]
              .splice(0, maxShowNotAvailableItems)
              .map((item, index) => (
                <div
                  className={cvaItem()}
                  key={index}
                >
                  <div
                    className={cvaItemImageWrap()}
                  >
                    {item.product.images &&
                    item.product.images.length ? (
                      <Image
                        src={
                          item.product.images[0]
                        }
                        className={cvaItemImage({
                          disabled: true,
                        })}
                        fill
                        alt=""
                      />
                    ) : null}
                  </div>
                </div>
              ))}
          </div>
          {notAvailableItems?.length > 4 ? (
            <div
              className={showMoreItems()}
              onClick={() =>
                onClickShowMoreNotAvailableItems()
              }
            >
              <span
                className={showMoreItemsText()}
              >
                {notAvailableItems.length} товаров
              </span>{' '}
              <IconCaretDown
                className={showMoreItemsIcon({
                  active:
                    maxShowNotAvailableItems ===
                    notAvailableItems.length,
                })}
              />
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  ) : (
    <div className={cvaRoot()}>
      <div className={cvaGroup()}>
        <div className={cvaGroupTitle()}>
          Товаров нет в наличии
        </div>
      </div>
    </div>
  );
};

export default StoreMapDetailsBody;
