import { cva } from 'class-variance-authority';

export const cvaRoot = cva([
  'StoreMapItemStyles-cvaRoot',
  'flex flex-col items-start',
  'px-3.2 lg:px-8 py-2.4',
  'cursor-pointer select-none',
  'transition-colors duration-300',
  'border-b border-0.1 border-cBlack border-opacity-[0.08]',
  'hover:bg-cMediumGreyOnWhite',
]);

export const cvaAddress = cva([
  'StoreMapItemStyles-cvaAddress',
  'block w-full',
  'relative',
  'pr-3.2',
  'mb-0.4',
  'text-bodyM !font-semibold',
]);

export const cvaAddressIcon = cva([
  'StoreMapItemStyles-cvaAddressIcon',
  'absolute top-0.5 right-0',
  'w-1.6 h-1.6',
  '!stroke-cBlack',
]);

export const cvaDate = cva([
  'StoreMapItemStyles-cvaDate',
  'text-bodyM text-cBlack text-opacity-[0.48]',
  '[&>span]:text-cMediumGreen',
]);

export const cvaWorkingHours = cva([
  'StoreMapItemStyles-cvaWorkingHours',
  'inline-block',
  'relative',
  'pl-2.4',
  'mb-2.4',
  'text-bodyM text-cBlack text-opacity-[0.48]',
]);

export const cvaWorkingHoursIcon = cva([
  'StoreMapItemStyles-cvaWorkingHoursIcon',
  'absolute left-0 top-1/2 -translate-y-1/2',
  'w-1.6 h-1.6',
  'fill-cBlack opacity-[0.48]',
]);

export const cvaCount = cva(
  [
    'DeliveryOptionStyles-cvaCount',
    'block',
    'relative',
    'pl-3 pr-0.8 pb-0.1',
    'text-bodyM text-cWhite !font-semibold',
    'rounded-[0.8rem]',
  ],
  {
    variants: {
      allItems: {
        true: ['bg-cMediumGreen'],
        false: ['bg-cExtraOrange'],
      },
    },
  }
);

export const cvaCountIcon = cva([
  'DeliveryOptionStyles-cvaCountIcon',
  'absolute left-0.8 top-1/2 -translate-y-1/2',
  'w-1.2 sm:w-1.6 h-1.2 sm:h-1.6',
]);

export const cvaPhone = cva([
  'StoreMapDetailsHeaderStyles-cvaPhone',
  'relative',
  'pl-2.4',
  'mb-0.4',
  'text-bodyM !font-semibold text-cMediumGreen',
]);

export const cvaPhoneIcon = cva([
  'StoreMapDetailsHeaderStyles-cvaPhoneIcon',
  'absolute top-1/2 left-0 -translate-y-1/2',
  'w-1.6 h-1.6',
]);
