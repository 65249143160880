import { cva } from 'class-variance-authority';

export const cvaRoot = cva([
  'PvzMapStyles-cvaRoot',
  'fixed left-0 top-0 z-[200]',
  'w-full h-screen',
  'bg-cWhite',
]);

export const cvaMapWrapper = cva([
  'PvzMapStyles-cvaMapWrapper',
  'absolute w-full md:w-[calc(100%-41rem)] lg:w-[calc(100%-52rem)] h-full left-0 top-0',
]);

export const cvaMap = cva([
  'PvzMapStyles-cvaMap',
  'absolute w-full h-full left-0 top-0',
]);

export const cvaSheet = cva([
  'PvzMapStyles-cvaSheet',
  'relative z-[1200]',
  'md:hidden',
  'bg-cWhite',
]);

export const cvaAside = cva(
  [
    'StoresMap-cvaAside',
    'hidden md:flex flex-col',
    'fixed right-0 top-7.2 md:top-0 bottom-0 md:bottom-auto z-[10] overflow-hidden',
    'w-full md:w-[44rem] lg:w-[56rem] md:h-screen ',
    'bg-cWhite',
    'rounded-[3.2rem] rounded-tr-none rounded-br-none',
  ],
  {
    variants: {
      paddingBottom: {
        true: ['pb-8'],
        false: [''],
      },
      showDetails: {
        true: [''],
        false: ['hidden md:flex'],
      },
    },
    defaultVariants: {
      paddingBottom: false,
      showDetails: false,
    },
  }
);

export const cvaAsideHeader = cva([
  'PvzMapStyles-cvaAsideHeader',
  'px-3.2 lg:px-8 pt-1.2 sm:pt-3.2 md:pt-4.8 pb-3.2',
  'w-full',
  'bg-cLightGrey',
]);

export const cvaAsideHeaderTitle = cva([
  'PvzMapStyles-cvaAsideHeaderTitle',
  'mb-1.6 sm:mb-2.4',
  'text-h3 font-secondary',
]);

export const cvaAsideHeaderActiveCity = cva([
  'PvzMapStyles-cvaAsideHeaderActiveCity',
  'text-cMediumGreen',
  'border-b border-0.1 border-cMediumGreen border-dashed',
  'cursor-pointer',
]);

export const cvaAsideScroll = cva([
  'PvzMapStyles-cvaAsideScroll',
  'overflow-y-auto',
]);

export const cvaAsideItems = cva([
  'PvzMapStyles-cvaAsideItems',
  'w-full',
]);

export const cvaClose = cva([
  'PvzMapStyles-cvaClose',
  'flex items-center justify-center',
  'w-4 md:w-5.6 h-4 md:h-5.6',
  'bg-cWhite',
  'rounded-[1.2rem] md:rounded-[1.6rem]',
  'cursor-pointer select-none',
  'transition-colors duration-300',
  'shadow-mapClose',
  'group',
]);

export const cvaCloseIcon = cva([
  'PvzMapStyles-cvaCloseIcon',
  'w-1.6 h-1.6',
  '[&>path]:stroke-cBlack',
  '[&>path]:ransition-colors [&>path]:duration-300',
]);

export const cvaBack = cva([
  'PvzMapStyles-cvaBack',
  'flex items-center justify-center',
  'w-4 md:w-5.6 h-4 md:h-5.6',
  'bg-cWhite',
  'rounded-[1.2rem] md:rounded-[1.6rem]',
  'cursor-pointer select-none',
  'transition-colors duration-300',
  'shadow-mapClose',
  'group',
]);

export const cvaBackIcon = cva([
  'PvzMapStyles-cvaBackIcon',
  'w-2.4 h-2.4',
  '-rotate-180',
  '[&>path]:stroke-cBlack',
  '[&>path]:ransition-colors [&>path]:duration-300',
]);

export const cvaZoom = cva([
  'PvzMapStyles-cvaZoom',
  'absolute right-2.4 md:right-6.4 top-1/2 -translate-y-1/2 z-[10]',
  'flex flex-col gap-1.2',
]);

export const cvaZoomButton = cva([
  'PvzMapStyles-cvaZoomButton',
  'flex items-center justify-center',
  'w-4 md:w-5.6 h-4 md:h-5.6',
  'bg-cWhite',
  'rounded-[1.2rem] md:rounded-[1.6rem]',
  'cursor-pointer select-none',
  'transition-colors duration-300',
  'shadow-mapClose',
  'group',
]);

export const cvaZoomButtonIcon = cva([
  'PvzMapStyles-cvaZoomButtonIcon',
  'w-1.6 h-1.6',
  'stroke-cBlack',
  'transition-colors duration-300',
]);

export const cvaStoreDetails = cva([
  'PvzMapStyles-cvaStoreDetails',
  'bg-cWhite pb-8 sm:pb-0',
]);

export const cvaAsideButtonWrapper = cva([
  'PvzMapStyles-cvaAsideButtonWrapper',
  'absolute left-0 bottom-0',
  'w-full',
  'bg-cWhite px-2.4 sm:px-3.2 lg:px-8 sm:py-2.4 pb-2 py-2.4',
]);

export const cvaAsideButton = cva([
  'PvzMapStyles-cvaAsideButton',
  'block w-full',
  'text-bodyM sm:text-bodyMSemibold',
]);

export const cvaSearch = cva([
  'PvzMapStyles-cvaSearch',
  'relative',
  'not-last:mb-1.6 sm:not-last:mb-2.4',
]);

export const cvaSearchInput = cva([
  'PvzMapStyles-cvaSearchInput',
  'block',
  'w-full h-4.8 sm:h-5.6',
  'py-1.6 px-2.4',
  'bg-cWhite',
  'rounded-[1.6rem]',
  'border-none outline-none',
  'text-bodyM placeholder:text-cBlack placeholder:text-opacity-[0.48]',
  'focus:placeholder:opacity-0',
]);

export const cvaSearchIcon = cva([
  'PvzMapStyles-cvaSearchIcon',
  'absolute z-[1] right-1.6 lg:right-2.4 top-1/2 -translate-y-1/2',
  'w-2.4 h-2.4',
  'pointer-events-none',
]);

export const cvaTags = cva(
  ['PvzMapStyles-cvaTags', 'flex-wrap gap-0.8'],
  {
    variants: {
      hidden: {
        true: 'hidden',
        false: 'flex',
      },
    },
  }
);

export const cvaTag = cva(
  [
    'PvzMapStyles-cvaTag',
    'py-1.1 px-1.6',
    'text-bodyM',
    'rounded-[1.6rem] border border-0.1 border-cBlack border-opacity-[0.32]',
    'transition-colors duration-300',
    'cursor-pointer select-none',
  ],
  {
    variants: {
      active: {
        true: [
          'bg-cDarkGreyOnGrey border-cDarkGreyOnGrey',
        ],
        false: [''],
      },
    },
  }
);

export const cvaLoadingContainer = cva([
  'PvzMapStyles-cvaLoadingContainer',
  'w-full h-full',
  'flex items-center justify-center',
]);

export const cvaBottomSheetHeader = cva(
  [
    'PvzMapStyles-cvaBottomSheetHeader',
    // 'before:opacity-0 after:opacity-0',
    '!py-1',
  ],

  {
    variants: {
      bottom: {
        true: [
          'PvzMapStyles-cvaBottomSheetHeader--bottom',
        ],
        false: [''],
      },
      showArrow: {
        true: [],
        false: [
          'before:opacity-0 after:opacity-0',
        ],
      },
    },
    defaultVariants: {
      bottom: false,
    },
  }
);
