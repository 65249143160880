import {
  DependencyList,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

export const useMediaValues = (
  values: Record<number, any>,
  initial: number,
  deps?: DependencyList
) => {
  const sortedBreakpoints = useMemo(() => {
    return Object.entries(values).sort(
      ([a], [b]) =>
        Number.parseFloat(a) -
        Number.parseFloat(b)
    );
  }, deps ?? []);

  const getValue = useCallback(
    (initial?: number) => {
      const breakpoints = sortedBreakpoints.map(
        ([breakpoint, value]) => ({
          breakpoint,
          value,
          isActive:
            typeof initial === 'number'
              ? initial >=
                Number.parseFloat(breakpoint)
              : matchMedia(
                  `(min-width: ${breakpoint}px)`
                ).matches,
        })
      );

      return (
        [...breakpoints]
          .reverse()
          .find((i) => Boolean(i.isActive))
          ?.value ?? null
      );
    },
    [sortedBreakpoints]
  );

  const [value, setValue] = useState<any>(() =>
    getValue(initial)
  );

  useEffect(() => {
    const updateValue = () =>
      setValue(getValue());
    updateValue();

    const mediaQueryLists: MediaQueryList[] =
      sortedBreakpoints.map(([breakpoint]) =>
        matchMedia(`(min-width: ${breakpoint}px)`)
      );

    mediaQueryLists.forEach((mediaQueryList) => {
      mediaQueryList.addEventListener(
        'change',
        updateValue
      );
    });

    return () => {
      mediaQueryLists.forEach(
        (mediaQueryList) => {
          mediaQueryList.removeEventListener(
            'change',
            updateValue
          );
        }
      );
    };
  }, [sortedBreakpoints]);

  return value;
};
